// ------------------------------------------------ fonts ------------------------------------------------
@font-face {
	font-family: 'Aeonik-Light';
	src: url('./assets/fonts/aeonik/Aeonik-Light.woff') ;
}
@font-face {
	font-family: 'Aeonik-Bold';
	src: url('./assets/fonts/aeonik/Aeonik-Bold.woff');
}
@font-face {
	font-family: 'Freight';
	src: url('./assets/fonts/FreightBigBookItalic/Freight.otf')
		format('opentype');
}
@font-face {
	font-family: 'Aeonik-Medium';
	src: url('./assets/fonts/aeonik/Aeonik-Medium.woff');
}
@font-face {
	font-family: 'Aeonik-Regular';
	src: url('./assets/fonts/aeonik/Aeonik-Regular.woff');
}
@font-face {
	font-family: 'Outer-sans-Regular';
	src: url('./assets/fonts/MADE OUTER SANS REGULAR PERSONAL USE.OTF')
		format('opentype');
}
@font-face {
	font-family: 'Freight';
	src: url('./assets/fonts/FreightBigBookItalic/Freight.otf')
		format('opentype');
}
@font-face {
	font-family: 'Aire-Italic';
	src: url('./assets/fonts/AIRE_BOLD_ITALIC_STD.OTF') format('opentype');
}
@font-face {
	font-family: 'Outer-Regular';
	src: url('./assets/fonts/MADE OUTER SANS REGULAR PERSONAL USE.OTF')
		format('opentype');
}

// ------------------------------------------------main variables ------------------------------------------------
$bigSize: 30px;
$mediumSize: 20px;
$smallSize: 15px;
$mainTextColor: #ffffff;
$mainBackgroundColor: #222222;
$LightFont: 'Aeonik-Light';
$BoldFont: 'Aeonik-Bold';
// colors

$DarkTextColor: #000000;
$BackgroundColor_MediumTextColor: #222222;
$BackgroundColor_MediumTextColor: #666666;
$BackgroundColor_MediumTextColor: #666666;

$LightDarkTextColor: #aaaaaa;
$LightDarkTextColor: #eeeeee;
$LightTextColor: #ffffff;
// function

* {
	scrollbar-width: auto;
	scrollbar-color: rgba(34, 34, 34, 1) $mainTextColor;
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
}

// ---------------------------------------- ScrollBar ------------------------------------------------------------
::-webkit-scrollbar {
	width: 1.5vh;
	height: auto;
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	width: 8px;
	height: 8px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 4px;
}

// firefox scrollbar
::-moz-scrollbar {
	width: 10px;
	height: 10px;
}

.main-container {
	max-width: 1720px;
	margin: 0 auto;
	padding: 0 30px;

	width: 100%;

	@media (max-width: 768px) {
		padding: 0 15px;
	}
	// @media (min-width: 1720px) {
	// 	padding: 0px;
	// }
}

//------------------------------------------------ components ------------------------------------------------------
.dark-button {
	margin-top: 5vh;
	font-size: 0.9vw;
	background-color: transparent;
	border: none;
	width: inherit;
	display: flex;
	justify-content: flex-start;
	padding: 0;
	a {
		font-family: 'Aeonik-Regular';
		font-weight: 500;
		position: relative;
		padding: 1vh 2vw;
		border: 0.001rem solid $mainTextColor;
		margin-bottom: 0.1rem;
		border-radius: 2vw;
		text-decoration: none;
		color: $mainTextColor;
		display: inline-flex;
		align-items: flex-start;
		justify-content: flex-start;
		overflow: hidden;
		transform: translateZ(0);
		transition: border 0.65s ease-out;
		&::before {
			content: '';
			position: absolute;
			bottom: -0.1rem;
			left: 0;
			width: 100%;
			height: calc(100% + 3.2vh);
			transform: translate3d(0, 100%, 0);
			color: $mainBackgroundColor;
			background-color: $mainTextColor;
			transition: transform 0.35s ease-out;
			z-index: -1;
			box-sizing: inherit;
		}
		&:hover::before {
			transform: translate3d(0, 0, 0);
			z-index: -1;
		}
		&:hover {
			border: 0.001rem solid $mainBackgroundColor;
			color: $mainBackgroundColor;
		}
	}
}

.row > * {
	padding: 0 !important	;
}

.container-fluid .row .small-img .medium-img-item img,
.container-fluid .row .small-img .medium-img-item a {
	cursor: none !important;
}
.container-fluid .row .small-img,
.container-fluid .row .medium-img-item {
	cursor: none;
}
.main-img .medium-img-item img,
.main-img .medium-img img {
	cursor: none !important;
}
.custom-none {
	cursor: none !important;
}

.white-button {
	margin-top: 5vh;
	font-size: 0.8vw;
	background-color: transparent;
	border: none;
	width: inherit;
	display: flex;
	justify-content: flex-start;
	padding: 24px 0;
	a {
		font-size: 16px;
		font-family: 'Aeonik-Regular';
		font-weight: 500;
		text-transform: uppercase;

		position: relative;
		padding: 2vh 2.8vw;
		border: 0.001rem solid $mainBackgroundColor;
		margin-bottom: 0.1rem;
		border-radius: 2vw;
		text-decoration: none;
		color: $mainBackgroundColor;
		display: inline-flex;
		align-items: flex-start;
		justify-content: flex-start;
		overflow: hidden;
		transform: translateZ(0);
		transition: border 0.65s ease-out;
		&::before {
			content: '';
			position: absolute;
			bottom: -0.1rem;
			left: 0;
			width: 100%;
			height: calc(100% + 3.2vh);
			transform: translate3d(0, 100%, 0);
			color: $mainTextColor;
			background-color: $mainBackgroundColor;
			transition: transform 0.35s ease-out;
			z-index: -1;
			box-sizing: inherit;
		}
		&:hover::before {
			transform: translate3d(0, 0, 0);
			z-index: -1;
		}
		&:hover {
			border: 0.001rem solid $mainTextColor;
			color: $mainTextColor !important;
		}
	}
}
//--------------------------------------------------- loader ------------------------------------------------

.main-img-load {
	background-color: #eee;
	width: 100%;
	height: 80vh;

	.loader {
		animation-duration: 2s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: darkgray;
		background: linear-gradient(
			to right,
			#eeeeee 10%,
			#dddddd 18%,
			#eeeeee 33%
		);
		background-size: cover;
		height: 80vh;
		position: relative;
	}
}
.medium-img-load {
	background-color: #eee;
	width: 40%;
	height: 40vh;
	margin: 10vh 0;

	.loader {
		animation-duration: 2.25s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: darkgray;
		background: linear-gradient(
			to right,
			#eeeeee 10%,
			#dddddd 18%,
			#eeeeee 33%
		);
		background-size: cover;
		height: 40vh;
		position: relative;
	}
}
// mobile
@media only screen and (max-width: 770px) {
	.home {
		margin-top: 10vh;
		overflow-x: hidden;

		.title {
			margin-top: 6vh;
			h1 {
				font-size: 12vw;
				line-height: 12vw;
				margin: 0;
				padding: 0;
			}
		}

		// .main-video {
		// 	margin: 6vh 0;
		// 	padding: 0 !important;
		// 	padding-right: 5vw !important;
		// 	padding-bottom: 5vh !important;
		// 	border-bottom: 1px solid rgba(54, 54, 54, 1);

		// 	&:hover {
		// 		cursor: none;
		// 	}
		// }

		.second__screen {
			display: flex;
			flex-direction: column;
			margin: 0 auto !important;
			width: 100% !important;
			padding: 0;

			h2 {
				line-height: 7vw;
				font-size: 5vw;
				word-spacing: 0.8rem;
				font-weight: 500;
				color: rgba(34, 34, 34, 1);
				font-family: 'Aeonik-Medium';
			}
		}

		.category__screen {
			width: 100vw;
			max-height: 150vh;
			min-height: 100vh;
			position: relative;

			&-img {
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				width: 100%;

				z-index: -1;
			}
			&-texts {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				font-size: 4.2em;
			}
			&-text {
				color: $mainTextColor;
				position: relative;
				font-family: 'Aeonik-Light';

				p::after {
					content: '';
					width: 100%;
					height: 0.45em;
					top: 37%;
					left: 0%;
					background-position: center;
					background-size: 100%;
					position: absolute;
					transform: rotateX(90deg);
					transition: 0.5s;
					z-index: -1;
				}
				p:hover {
					font-family: 'Aire-Italic';
				}

				p:hover::after {
					transform: rotateX(0);
				}
			}

			&-text:hover {
				color: #000;
				font-family: 'Freight';

				.back-line {
					transform: rotateX(0);
				}
			}
		}

		.categories {
			position: relative;
		}

		.back-line {
			top: 37%;
			position: absolute;
			left: -120%;
			height: 0.45em;
			background-color: yellow;
			width: 140vw;
			z-index: -2;
			transform: rotateX(90deg);
			transition: 0.5s;
			display: none;
		}
		.back-line span {
			font-size: 18px;
			margin: 0 10px;
			word-spacing: 10px;
			display: none;
		}
		.back-line-text {
			position: absolute;
			width: 105%;
			left: -22%;
			top: -180%;
			word-spacing: 10px;
			animation: animate 3s linear infinite;
		}
	}
	.block-title__title {
		font-size: 8vw !important;
		line-height: 8vw !important;
	}
	.portfolio-section {
		a {
			&[data-rb-event-key='home'] {
				display: none;
				margin: 0 5vw;

				font-size: 4.5vw;
			}
		}

		margin: 0 3vw;
		.nav {
			margin-top: 15vh;
			display: flex;
			justify-content: space-between;
			align-items: end;

			a {
				font-size: 3vw;
				&[aria-selected='true'] {
					font-size: 3.2vw;
				}
				&[data-rb-event-key='home'] {
					margin: 0 5vw;
					font-size: 4.5vw;
				}
				&:hover {
					border-bottom: 1px solid #000 !important;
				}
			}
		}
	}

	.about__page {
		width: 100%;
		padding: 3vh 5vw;
		background-color: rgba(25, 25, 25, 1);
		margin-top: 15vh;
		color: rgba(255, 255, 255, 1);

		&__title {
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			&-div,
			&-div2 {
				height: 100%;

				h1 {
					width: 45%;
					letter-spacing: 0.1em;
					font-size: 10vw;
					color: rgba(255, 255, 255, 1);
					padding: 0;
					margin: 0;
					.con2 {
						height: 10vh;
					}
					div {
						.main1,
						.main2 {
							height: 15vh !important;
						}
					}
				}
			}
			&-div2 {
				display: block;
				h1 {
					margin-left: 0;
				}
			}
		}
		&__content {
			display: flex;
			flex-direction: column;
			align-items: center;
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			padding: 5vh 0vh;
			justify-content: center;

			&__text {
				width: 100% !important;
				margin-top: 5vh;
				color: rgba(255, 255, 255, 1);
				font-size: 4vw;
				span {
					font-size: 5vw !important;
					color: rgba(255, 255, 255, 1);
				}
			}
			&__logo {
				width: 70% !important;
				height: 100%;
				display: flex;
				align-items: center;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
		&__author {
			display: block !important;
			&__logo {
				width: 100% !important;
				img {
					width: 100%;
				}
			}
			&__text {
				width: 100% !important;
				h3 {
					margin-top: 5vh;
					font-size: 10vw;
				}
				p {
					// width: 90% !important;
					font-size: 5vw;
				}
				&-role {
					// width: 90% !important;
					font-size: 4vw;
				}
			}
		}
		&__numbers {
			.rows {
				flex-direction: column !important;
				// .col-4,
				// .col-md-4 {
				// }
			}
			&__title {
				flex: none !important;
				width: 100% !important;
				h1 {
					font-size: 10vw;
					line-height: 10vw;
				}
			}

			&__main {
				margin: 0 !important;
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				flex: none !important;
				width: 100% !important;
				h1 {
					font-size: 8.3vw;
					line-height: 10vw;
				}

				h3 {
					font-size: 5vw;
					margin-top: 5vh;
				}
				p {
					width: 70%;
					font-size: 4vw;
					line-height: 6vw;
				}

				&-empty {
					display: none;
				}
			}
		}

		&__services {
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			padding: 5vh 0;
			&__title {
				h1 {
					font-size: 10vw;
					line-height: 4vw;
					letter-spacing: 0.2vw;
					margin: 3vh 0;
				}
			}
			&__main {
				display: flex;
				width: 100% !important;
				margin-left: 20vw;
				.service {
					h2 {
						// margin: 3vh 0;
						font-size: 5vw;
						line-height: 5vw;
					}
				}
			}
		}
		&__projects {
			&__title {
				display: flex;
				justify-content: space-between;
				align-items: end;
				margin-bottom: 5vh;
				h1 {
					font-size: 10vw;
					line-height: 10vw;
					color: rgba(255, 255, 255, 1);
				}
				.custom-button {
					display: none;
				}
			}
		}
		.bottom-content {
			margin-top: 2vh;
			p {
				font-size: 0.8vw;
				// margin: 0 3vw;
				font-size: 3vw;
			}
		}
	}

	.custom-button {
		border-radius: 50px;
		width: 100%;
		font-size: 5vw;
	}

	.contact {
		flex-direction: column;
		.contact-info {
			width: 100%;
			.title {
				h1 {
					font-size: 8vw;
					line-height: 5.8vw;
				}
			}
			.all,
			.clients,
			.messengers {
				h4 {
					font-size: 1.5em;
					letter-spacing: 0.3vw;
					line-height: 1.5em;
				}
				p {
					line-height: 2em;
					font-size: 1.5em;
				}
			}
			.messenger {
				a {
					font-size: 1.5em !important;
					line-height: 1.5em !important;
				}
			}
		}
		.contact-forms {
			margin-top: 15vh;
			.contact-form {
				.form-group {
					margin: 5vh 0;
					label {
						font-size: 1.5em;
					}
					input,
					textarea {
						font-size: 1.5em;
					}
					input[type='checkbox'] {
						width: 10vw;
						height: 10vh;
					}
				}
				.checkbox {
					display: flex;
					align-items: center;
					label {
						font-size: 1.2em;
						line-height: 1.5em;
					}
				}

				button {
					width: 100%;
					height: 15vh;
					padding: 0.5rem 2rem;
					font-size: 2em;
				}
			}
		}
	}

	.school__page {
		margin-top: 15vh;
		width: 100%;
		&__title {
			padding: 5vh 3vw;
			h1 {
				font-size: 15vw;
			}
			&-1 {
				margin: 0;
			}

			&-2 {
				margin-left: 15vw;
			}

			&-3 {
				margin-left: 25vw;
			}
		}

		.bottom-content {
			margin-top: 2vh;

			p {
				margin: 0 3vw;
				font-size: 3vw;
			}
		}
		&__about {
			display: flex;
			flex-direction: column;

			padding: 10vh 0;
			margin: 5vh 3vw;
			align-items: center;
			justify-content: space-between;
			h1 {
				font-size: 7.5vw;
				line-height: 10vw;
			}
			h3 {
				margin-top: 5vh;
				font-size: 5vw;
				line-height: 8vw;
			}
		}
		&__course {
			margin: 10vh 3vw;
			display: block;
			justify-content: space-between;
			align-items: center;

			h3 {
				margin: 5vh 0;
				font-size: 5vw;
			}

			h1 {
				font-size: 7vw;
				margin-bottom: 5vh;
			}
		}

		&__main {
			.mentor {
				margin: 5vh 3vw;
				.main {
					display: flex;
					flex-direction: column;
					width: 100%;
					margin: 10vh 0;
					justify-content: space-between;
					.mentor-img {
						width: 100%;
						img {
							max-width: 100%;
							height: auto;
						}
					}
					.about-mentor {
						width: 100%;
						h1 {
							margin-top: 5vh !important;
							text-transform: uppercase;
							font-weight: 700;
							font-style: normal;
							font-size: 5vw;
							font-weight: bold;
							margin: 0;
						}
						p {
							font-size: 1.2rem;
							margin: 5vh 0;
							font-weight: 200;
							font-style: normal;
						}
						.messengers {
							display: flex;
							width: 100%;
							justify-content: space-around;
							span {
								border: 1px solid $mainTextColor;
								border-radius: 50%;
								padding: 1.5vw;
								margin: 0 1vw;
								width: 5vh;
								height: 5vh;
								text-align: center;

								vertical-align: middle;

								svg {
									width: 100%;
									height: 100%;
								}
							}
						}
					}
					#video > iframe {
						width: 90vw !important;
						height: 50vh !important;
					}
				}
			}

			&__tutor {
				.nav-tabs {
					border-bottom: rgba(119, 119, 119, 1) 1px solid !important;
				}
				.nav {
					margin-top: 15vh;
					display: flex;
					justify-content: space-around;
					align-items: end;

					a {
						font-size: 4.25vw;
						letter-spacing: 10%;
						transition: 0.5s ease all;
						&[aria-selected='true'] {
							border-bottom: 1px solid $mainTextColor !important;
						}
						&[data-rb-event-key='home'] {
							font-size: 4vw;
							display: none;
							// border-bottom: 1px solid $mainTextColor !important;
						}
					}
				}
				.school__page__about {
					h1 {
						font-size: 7.5vw;
						line-height: 10vw;
					}
					h3 {
						margin-top: 5vh;
						font-size: 5vw;
						line-height: 8vw;
					}
					button {
						margin: 3vw;
					}
				}
			}
		}
	}

	.header {
		.dots {
			display: inline !important;
		}
		.toggle__icon {
			display: block;
		}
		.mobile-header {
			position: fixed;
			background-color: #000;
			width: 100%;
			height: 100vh;
			top: 0;
			left: 100%;
			z-index: 9999;
			display: flex;
			flex-direction: column;
			transition: all 0.5s ease;
			&__head {
				margin: 5vh;
				display: flex;
				justify-content: space-between;
			}
			&__main {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				margin-top: 5vh;
				h3 {
					margin-top: 5vh;
				}
				h3 > * {
					text-transform: uppercase;
					color: $mainTextColor;
					text-decoration: none;
				}
			}
		}

		.header__logo {
			display: flex;
			justify-content: flex-end;
			padding-left: 6vh;
		}
		.header__menu {
			display: none !important;
		}
		.toggle__icon {
			margin-right: 5vw;

			span {
				svg {
					width: 5vw !important;
					height: 5vw !important;
				}
			}
		}
	}

	footer {
		.footer_section-email {
			h4 {
				font-size: 24px;
			}
		}
		.col-md-6 {
			div {
				height: 5vh;
			}
		}

		.footer__section2-text {
			display: none !important;
		}
		.footer__section2-links2 {
			display: flex !important;

			h3 {
				font-size: 2vw;
			}
		}
		.footer__section2-links {
			display: none;
			div {
				h4 {
					font-size: 2.5vw;
				}

				ul li {
					a {
						font-size: 3vw;
					}
				}
			}
		}

		.footer-end {
			p {
				font-size: 2.5vw;
			}
		}
	}
	.mobile-title {
		font-size: 15vw;
		margin-top: 30vh;
		display: block !important;
		font-family: 'Aeonik-Medium';
		text-transform: uppercase;
		margin-bottom: 2vh;
		width: 100%;
		color: rgba(0, 0, 0, 1);
		font-family: 'Aeonik-Bold';
	}
	.mentor-name {
		color: $mainTextColor;
		margin: 0 auto;
	}
}
