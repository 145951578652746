.loader-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

// .loader-section {
// 	position: absolute;
// 	top: 0%;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	background-color: rgba(0, 0, 0, 1);
// 	display: flex;
// 	flex-direction: column;
// 	text-align: left;
// 	align-items: center;
// 	justify-content: center;

// 	p {
// 		width: fit-content;
// 		font-family: 'Aeonik-Bold';
// 		position: relative;
// 		color: rgba(255, 255, 255, 1);
// 		font-size: 8vh;
// 		margin: 0;
// 		padding: 0;
// 		height: 1em;
// 		text-transform: uppercase;
// 	}
// 	.group {
// 		font-size: 5vh;
// 		margin-right: 6.5vh;
// 	}
// 	p:before {
// 		// height: 1vh;
// 		content: attr(data-text);
// 		position: absolute;
// 		overflow: hidden;
// 		min-height: 0em;
// 		white-space: nowrap;
// 		color: rgba(35, 36, 11, 0.932);
// 		z-index: 1;
// 		animation: infinite loading 2s linear;
// 	}
// 	@keyframes loading {
// 		0% {
// 			max-height: 2em;
// 		}
// 		50% {
// 			max-height: 1em;
// 		}

// 		100% {
// 			max-height: 0em;
// 		}
// 	}
// }

// // responsive
// @media (max-width: 768px) {
// 	.loader-section {
// 		.block {
// 			p {
// 				font-size: 3em;
// 			}
// 		}
// 	}
// }

// .loader-section {
// 	background-color: #000;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	z-index: 9999;
// 	color: #fff;
// 	text-transform: uppercase;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	.title-1 {
// 		width: 100%;
// 		display: flex;
// 		justify-content: center;
// 		height: 100%;
// 		align-items: center;
// 	}
// 	.section1 {
// 		position: absolute;
// 		top: 40%;
// 		left: 0%;
// 	}
// 	h1,
// 	.small {
// 		display: flex;
// 		font-size: 2rem;
// 		margin: 0 2vw;
// 	}

// 	.title-2 {
// 		display: none;
// 		width: 100%;
// 		justify-content: center;
// 		height: 100%;
// 		align-items: center;
// 		justify-content: space-between;
// 		margin: 0 10vw;

// 		.anime1 {
// 			position: relative;
// 			animation: anime1 1s infinite;
// 			transform: rotateX(90deg);
// 			margin-top: 50px;
// 		}
// 		.anime2 {
// 			position: absolute;
// 			right: 13.5%;
// 			animation: anime2 1s infinite;
// 			transform: rotateX(0deg);
// 			margin-top: 0px;
// 		}

// 		@keyframes anime1 {
// 			0% {
// 				transform: rotateX(0deg) rotateY(0deg);
// 				transform-origin: center;
// 			}
// 			100% {
// 				transform: rotateX(90deg);
// 				margin-top: 50px;
// 			}
// 		}
// 		@keyframes anime2 {
// 			0% {
// 				transform: rotateX(90deg) rotateY(0deg);
// 				transform-origin: center;
// 				margin-top: -50px;
// 			}
// 			100% {
// 				transform: rotateX(0deg);
// 				margin-top: 0px;
// 			}
// 		}
// 	}
// }

// @media only screen and (max-width: 770px) {
// 	.loader-section {
// 		.title-1 {
// 			display: flex;
// 			justify-content: center;
// 			flex-direction: column;
// 			align-items: flex-start;
// 			margin-left: 10vw;
// 		}
// 		.section1 {
// 			position: relative;
// 			top: auto;
// 			margin: 0;
// 			// margin-top: 20vh;
// 		}
// 		.small {
// 			margin: 0 10vh !important;
// 			font-size: 1.2rem;
// 		}
// 		h1 {
// 			margin: 0 !important;
// 			font-size: 1.2rem;
// 		}

// 		img {
// 			margin: 5vh 0;
// 		}

// 		.title-2 {
// 			display: flex;
// 			flex-direction: column;
// 			justify-content: center;
// 			align-items: flex-start;
// 			h1 {
// 				display: flex;
// 				flex-direction: column;
// 			}
// 		}
// 		.anime1,
// 		.anime2 {
// 			margin-left: 10vh;
// 			right: auto !important;
// 			position: relative !important;
// 		}
// 	}
// }
