/* play with vars for different effets */
:root {
	--color-cursor: 247, 202, 24;
	--cursor-outline-shade: 0.5;
	--cursor-size: 5px;
	--cursor-outline-size: 10px;
}

#cursor-dot,
#cursor-dot-outline {
	visibility: hidden;
	z-index: 999;
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	opacity: 0;
	transform: translate(-50%, -50%);
	transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

#cursor-dot {
	width: 50px;
	height: 50px;
	background-color: rgba(var(--color-cursor), 1);
}

#cursor-dot-outline {
	width: 80px;
	height: 80px;
	background-color: rgba(var(--color-cursor), var(--cursor-outline-shade));
}

#cursor-dot2,
#cursor-dot-outline2 {
	visibility: hidden;
	z-index: 999;
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	opacity: 0;
	transform: translate(-50%, -50%);
	transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

#cursor-dot2 {
	width: 50px;
	height: 50px;
	background-color: #a3a3a3;
}

#cursor-dot-outline2 {
	width: 80px;
	height: 80px;
	background-color: #343434;
}
