.contact {
	margin: 200px auto !important;
	display: flex;
	// padding: 0 !important;
	justify-content: space-between;
	.contact-info {
		.title {
			margin-bottom: 2rem;
			text-transform: uppercase;
			h1 {
				font-size: 4.5vw;
				color: rgba(0, 0, 0, 1);
				font-weight: 700;
				font-style: normal;
				line-height: 5.8vw;
				font-family: 'Aeonik-Bold';
			}
		}
		.all,
		.clients,
		.messengers {
			h4 {
				font-weight: 700;
				color: rgba(0, 0, 0, 1);
				font-style: normal;
				line-height: 1.5vw;
				font-family: 'Aeonik-Bold';
				font-size: 1.25vw;
				letter-spacing: 0.2vw;
				text-transform: uppercase;
			}
			p {
				font-weight: 100;
				color: rgba(0, 0, 0, 1);
				font-style: normal;
				line-height: 1.5vw;
				font-family: 'Aeonik-Light';
				font-size: 1.25vw;
				// letter-spacing: 0.2vw;
			}

			.messenger {
				a {
					text-decoration: none;
					border-bottom: 1px solid #000;
					font-weight: 100;
					color: rgba(0, 0, 0, 1);
					font-style: normal;
					line-height: 1.5vw;
					font-family: 'Aeonik-Light';
					font-size: 1.25vw;
				}
			}
		}
	}
	.contact-forms {
		margin-top: 50px;
		.contact-form {
			.form-group {
				margin-bottom: 1rem;
				label {
					font-weight: 300;
					color: rgba(34, 34, 34, 1);
					font-style: normal;
					line-height: 1vw;
					font-family: 'Aeonik-Regular';
					font-size: 1vw;
				}
				input,
				textarea {
					font-weight: 300;
					color: #000;
					background-color: rgba(238, 238, 238, 1);
					border: none;
					font-family: 'Aeonik-Regular';
					outline: none;
					font-size: 1vw;
				}
				input[type='checkbox'] {
					width: 3vw;
					height: 3vh;
					border: none;
					font-family: 'Aeonik-Regular';
				}
			}
			.checkbox {
				display: flex;
				align-items: center;
				label a {
					font-family: 'Aeonik-Regular';
					color: #000;
				}
			}

			button {
				background-color: rgba(238, 238, 238, 1);
				padding: 0.5rem 2rem;
				outline: none;
				border: none;
				font-weight: 700;
				font-style: normal;
				font-family: 'Aeonik-Bold';
				transition: all 0.3s ease-in-out;
				&:hover {
					background-color: rgba(0, 0, 0, 1);
				}
			}
		}
	}
}
