.about__page {
	font-family: 'Aeonik';
	width: 100%;
	padding: 3vh 5vw;
	background-color: rgba(25, 25, 25, 1);
	margin-top: 15vh;
	color: rgba(255, 255, 255, 1);

	&__title {
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		&-div,
		&-div2 {
			height: 100%;

			h1 {
				width: 45%;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				font-size: 7vw;
				font-family: 'Aeonik-Bold';
				font-style: normal;
				color: rgba(255, 255, 255, 1);
				padding: 0;
				margin: 0;
			}
		}
		&-div2 {
			display: flex;
			h1 {
				margin-left: 30vw;
			}
			justify-content: center;
		}
	}
	&__content {
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		padding: 5vh 0vh;
		padding-bottom: 14vh;
		&__text {
			color: rgba(255, 255, 255, 1);

			font-weight: 200;
			font-size: 1.25vw;
			font-style: normal;
			font-family: 'Aeonik-Medium';
			span {
				color: rgba(255, 255, 255, 1);
				font-weight: 700;
			}
		}
		&__logo {
			width: 35% !important;
			height: 100%;
			display: flex;
			align-items: center;
			img {
				width: 90%;
				height: 100%;
				object-fit: contain;
			}
			&-text {
				font-weight: 700;
				text-transform: uppercase;
				font-size: 4vw;
				font-family: 'Aeonik-Bold';
				font-style: normal;
				color: rgba(255, 255, 255, 1);
				padding: 0;
				margin: 0;
			}
		}
	}
	&__author {
		align-items: center;
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		padding: 5vh 0vw;
		justify-content: center;

		&__logo {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			img {
				width: 65%;
				height: 100%;

				object-fit: contain;
			}
		}
		&__text {
			h3 {
				color: rgba(255, 255, 255, 1);
				font-weight: 700;
				font-family: 'Aeonik-Bold';
				font-size: 3.3vw;
				text-transform: uppercase;
				margin: 0;
			}
			&-role {
				line-height: 2vw !important;
				font-size: 1.25vw !important;
				font-family: 'Aeonik-Bold' !important;
				font-weight: 500 !important;
				color: rgba(191, 191, 191, 1) !important;
				margin: 0 !important;
				margin-bottom: 5vh !important;
			}
			p {
				color: rgba(255, 255, 255, 1);
				font-family: 'Aeonik-Light';
				font-size: 1.6vw;
				font-weight: 200;
				// line-height: ;
			}
		}
	}
	&__numbers {
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		margin-top: 16vh !important;
		padding-bottom: 14vh;
		&__title {
			color: rgba(255, 255, 255, 1);
			h1 {
				text-transform: uppercase;
				font-weight: 700;
				font-size: 3.3vw;
				font-style: normal;
				font-family: 'Aeonik-Bold';
				line-height: 4vw;
			}
		}

		&__main {
			h1 {
				font-style: normal;
				font-family: 'Aeonik-Bold';
				font-size: 8.3vw;
				font-weight: 700;
				line-height: 10vw;
			}

			h3 {
				font-style: normal;
				font-family: 'Aeonik-Bold';
				text-transform: uppercase;
				font-size: 2vw;
				font-weight: 700;
			}
			p {
				width: 70%;
				font-style: normal;
				font-family: 'Aeonik-Medium';
				font-size: 1.25vw;
				line-height: 1.5vw;
				font-weight: 500;
			}
		}
	}

	&__services {
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		padding: 5vh 0;
		margin-top: 8vh;
		padding-bottom: 16vh;
		&__title {
			h1 {
				font-size: 3.3vw;
				line-height: 4vw;
				letter-spacing: 0.2vw;
				color: rgba(255, 255, 255, 1);
				font-weight: 700;
				font-style: normal;
				font-family: 'Aeonik-Bold';
				margin: 3vh 0;
			}
		}
		&__main {
			.service {
				h2 {
					color: rgba(191, 191, 191, 1);
					text-transform: uppercase;
					margin: 3vh 0;
					font-weight: 700;
					font-style: normal;
					font-family: 'Aeonik-Bold';
					font-size: 2vw;
					line-height: 2.5vw;
				}
			}
		}
	}
	&__team {
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		padding-bottom: 25vh;
		margin-top: 16vh;

		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 120px 30px;

		cursor: none !important;
		&-item {
			position: relative;

			h1 {
				position: absolute;
				bottom: 10%;

				left: 12px;

				font-family: 'Aeonik-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 40px;
				line-height: 49px;
				color: #ffffff;
				z-index: 20;
			}
			p {
				font-family: 'Aeonik-Medium';
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 22px;
				/* identical to box height */

				z-index: 20;
				margin-top: 12px;
				color: #999999;
			}
			.bg-content {
				position: absolute;
				top: 0.5%;
				left: 3%;
				width: 94%;
				height: 88%;
				background-color: #f5e41b;
				z-index: -1;
				transition: all 0.3s ease-in-out;
			}
			&:hover > .bg-content {
				z-index: 1;
				transform: rotate(4.14deg);
			}
			&:hover > img,
			&:hover > h1 {
				z-index: 25 !important;
			}

			img {
				position: relative;
				width: 100%;
				z-index: 20;
			}
		}
	}
	&__projects {
		margin-top: 16vh;
		padding-bottom: 16vh;
		&__title {
			display: flex;
			justify-content: space-between;
			align-items: end;
			margin-bottom: 5vh;
			h1 {
				font-weight: 700;
				font-style: normal;
				font-family: 'Aeonik-Bold';
				font-size: 3.3vw;
				line-height: 4vw;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 1);
			}
			.button {
				margin-top: 5vh;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 1rem;
				color: rgba(255, 255, 255, 1);
				width: 20vw;
				border: 1px solid rgba(255, 255, 255, 1);
				border-radius: 50px;
				transition: all 0.6s ease-in-out;
				& > * {
					color: rgba(255, 255, 255, 1);
					text-decoration: none;
				}

				&:hover {
					background-color: rgba(54, 54, 54, 1);
					& > * {
						color: #fff;
					}
				}
			}
		}
	}
	.bottom-content {
		margin-top: 2vh;
		p {
			text-transform: uppercase;
			font-weight: 400;
			font-size: 0.8vw;
			color: rgba(255, 255, 255, 1);
			font-style: normal;
			font-family: 'Outer-sans-Regular';
		}
	}
}
