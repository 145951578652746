.container-fluid {
	padding: 0 10px !important;
	font-family: 'Aeonik';
	.row {
		.main-img {
			margin: 5vh 0;
			padding: 0;
			// height: 80vh;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				transition: all 0.6s ease;
				object-fit: cover;
				&:hover {
					transform: scale(1.03);
				}
			}
		}
		.medium-img {
			& + .medium-img {
				padding-left: 32px !important;
			}
			.medium-img-item {
				// height: 40vh;
				overflow: hidden;

				img {
					text-align: right;
					width: 100%;
					height: 492px;
					transition: all 0.6s ease;
					object-fit: cover;
					&:hover {
						transform: scale(1.03);
					}
				}
			}
		}

		.small-img {
			//every 3rd child
			padding-left: 32px !important;

			&:nth-child(3n + 1) {
				padding-left: 0 !important;
			}

			// height: 40vh;
			.medium-img-item {
				overflow: hidden;

				img {
					width: 100%;
					height: 40vh;
					transition: all 0.6s ease;
					object-fit: cover;
					&:hover {
						transform: scale(1.03);
					}
				}
			}
			h3 {
				cursor: default;
				margin-top: 0.5vh;
				color: rgba(0, 0, 0, 1);
				font-weight: 500;
				font-size: 1.6vw;
				font-family: 'Aeonik-Medium';
				text-transform: uppercase;
			}
			p {
				cursor: default;
				font-weight: 500;
				font-size: 1.25vw;
				font-family: 'Aeonik-Medium';
				color: rgba(170, 170, 170, 1);
				font-weight: 500;
			}
		}
	}
}
