.main-video ~ #cursor-dot,
#cursor-dot-outline {
	display: inline !important;
}

#video {
	height: 56vw;

	// @media only screen and (max-width: 1500px) {
	// 	height: 1000px !important;
	// }
	// @media only screen and (max-width: 1200px) {
	// 	height: 800px !important;
	// }

	// @media only screen and (max-width: 1000px) {
	// 	height: 600px !important;
	// }
}
.home {
	display: flex;
	flex-direction: column;
	// margin-top: 10vh;
	overflow-x: hidden;
	.top-projects-section {
		// margin: 0 30px;
		.title {
			margin-top: 140px;
			h2 {
				line-height: 2.5vw;
				font-size: 2vw;
				word-spacing: 0.8rem;
				font-weight: 500;
				color: rgba(34, 34, 34, 1);
				font-family: 'Aeonik-Medium';
			}
		}
	}

	.container2 {
		width: 100%;
		padding-right: var(--bs-gutter-x, 0.75rem);
		padding-left: var(--bs-gutter-x, 0.75rem);
		// margin-left: 3vw;
	}
	.title,
	.top-projects-section {
		margin-top: 140px;
		h1 {
			font-family: 'Aeonik-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 88px;
			line-height: 112px;
			/* identical to box height, or 127% */
			text-transform: uppercase;

			color: #000000;

			margin: 0;
			padding: 0;
		}
		h3 {
			font-family: 'Aeonik-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 40px;
			line-height: 48px;

			text-transform: uppercase;

			color: #000000;
		}
	}

	.main-video {
		// margin: 6vh 0;
		padding: 0 !important;
		padding-bottom: 5vh !important;
		.border-bt {
			margin-top: 2vh;
			border-bottom: 1px solid rgba(54, 54, 54, 1);
			width: 100%;
		}
		width: 100%;
		&:hover {
			cursor: none;
		}
	}

	.second__screen {
		display: flex;
		flex-direction: column;
		margin: 0;

		h2 {
			line-height: 2.5vw;
			font-size: 2vw;
			word-spacing: 0.8rem;
			font-weight: 500;
			color: rgba(34, 34, 34, 1);
			font-family: 'Aeonik-Medium';
			span {
				color: #000;
				font-weight: bold;
			}
		}
	}
	.category__screen {
		* {
			cursor: none !important;
		}

		// margin: 100vh 0;
		margin: 60px 0;
		width: 100%;
		&-div {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			width: 100%;
			height: 100%;
			position: relative;
			overflow: hidden !important;
		}
		&-images {
			width: 100%;
			overflow: hidden;
			height: 50vh;
		}
		&-image {
			width: 100%;
			height: 100%;
			transition: all 0.6s ease;
			object-fit: cover;
			&:hover {
				transform: scale(1.1);
				cursor: pointer;
			}
		}
	}
	.category__screen-title {
		padding-left: var(--bs-gutter-x, 0.75rem);
		width: fit-content;
		height: fit-content;
		display: flex;
		margin-top: 140px;
		width: 100%;
		// margin-bottom: 3vh;
		cursor: default !important;
		text-align: center;

		border-bottom: 1px rgba(170, 170, 170, 1) solid;

		a {
			text-decoration: none;
		}

		h4 {
			margin: 0 0 0 10vh;
			cursor: pointer;
			font-size: 24px;

			font-family: 'Aeonik-Regular';
			color: rgba(170, 170, 170, 1);
			position: relative;
			font-weight: 400;
			padding: 0;
		}
		&-active {
			color: rgba(34, 34, 34, 1) !important;
			border-bottom: 1px rgba(34, 34, 34, 1) solid;
			font-weight: bold;
			h1 {
				font-weight: bold;
			}
		}
	}
}

.carousel .slide {
	min-width: 20vw !important;
	margin: 0;
	position: relative;
	text-align: center;
}
.slideItem {
	width: 10vw !important;
}
.projects__screen {
	h2 {
		font-style: 'Aeonik-Bold';
		text-transform: uppercase;
		font-weight: 700;
		font-size: 2vw;
		line-height: 2.5vw;
		color: rgba(0, 0, 0, 1);
	}
}

//-------------------------------------------- PARTNERS -----------------------------------------------------------

.partner-div {
	// height: 15vh;

	img {
		object-fit: contain;
	}
}
.slick-slide {
	margin: 0 1vw !important;
	height: fit-content !important;
	width: auto !important;
}

.partners__screen {
	margin: 0;
	h2 {
		font-style: 'Aeonik-Bold';
		text-transform: uppercase;
		font-weight: 700;
		font-size: 2vw;
		line-height: 2.5vw;
		color: rgba(0, 0, 0, 1);
	}
}
.partner-logo {
	height: 180px;
	width: 200px;
	display: inline-block;
	margin: 10px 20px;
}

.partners-box {
	width: 200%;
}
.partners-carusel {
	img {
		animation: carusel 7s linear infinite;
	}
}
@keyframes carusel {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-2570px);
	}

	51% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-2570px);
	}
}

[data-aos='example-anim1'].aos-animate {
	transform: rotate(45deg);
	transition: 1s;
}
.animated-scroll {
	transition: all 0.5s linear;
	margin-top: 8vh !important;
}
.animated-scroll-r {
	transition: all 0.5s linear;
	margin-top: 0 !important;
}
