footer {
	font-family: 'Aeonik-Light';
	background-color: rgba(34, 34, 34, 1);
	text-align: center;
	font-style: normal;
	font-weight: 700;
	font-size: 1.25vw;
	color: rgba(255, 255, 255, 1);

	.col-md-6 {
		div {
			height: 22vh;
		}
	}
	.logo {
		display: flex;

		margin-top: 10vh;
		margin-bottom: 10vh;
	}
	.footer_section-email {
		h4 {
			text-transform: uppercase;
			font-family: 'Aeonik-Medium';
			font-style: normal;
			font-weight: 500;
			font-size: 1.25vw;
			color: rgba(255, 255, 255, 1);
		}
	}

	.footer_section-email,
	.contact-info {
		display: flex;
		margin-bottom: 10vh;

		flex-direction: column;
		h2 {
			font-family: 'Aeonik-Bold';
			font-style: normal;
			font-weight: 700;
			font-size: 64px;
			@media screen and (max-width: 1450px) {
				font-size: 30px;
			}

			@media screen and (max-width: 1000px) {
				font-size: 25px;
			}
			@media screen and (max-width: 850px) {
				font-size: 20px;
			}
		}
		div {
			img {
				width: auto;
				height: auto;
				margin-right: 0.5vw;
			}
			display: flex;
			margin-top: 22px;
		}

		.newsletter-form {
			form {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
			}

			.footer-button {
				background-color: transparent;
				border: none;
				position: absolute;
				right: 0;

				color: #fff;

				svg {
					margin-bottom: 20px;
				}
			}

			input[type='email'] {
				width: 100%;
				border: none;
				height: 2.5rem;
				line-height: 5.5rem;
				color: rgba(255, 255, 255, 1) !important;
				display: block;
				border-bottom: 0.1rem solid;
				border-radius: 0;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				background-color: transparent;
				outline: none;
				font-family: 'Aeonik-Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 1.25vw;
				position: relative;
			}
			input:focus,
			input:checked {
				background-color: transparent;
			}
			input:-internal-autofill-selected {
				appearance: menulist-button;
				background-image: none !important;
				background-color: transparent !important;
			}
		}
	}
	.footer_section-address {
		display: flex;

		margin-bottom: 10vh;
		h2 {
			font-family: 'Aeonik-Bold';
			font-size: 64px;
			font-weight: 700;
			text-align: left;

			@media only screen and (max-width: 1000px) {
				font-size: 25px;
			}
			@media only screen and (max-width: 1450px) {
				font-size: 30px;
			}
			@media only screen and (max-width: 850px) {
				font-size: 20px;
			}
		}
	}

	.footer__section2-text {
		h3 {
			font-style: normal;
			font-weight: 700;
			font-family: 'Aeonik-Bold';
			font-size: 1.6vw;
			text-align: initial;
		}
		display: flex;

		margin-top: 10vh;
		margin-bottom: 10vh;
	}
	.footer__section2-links {
		display: flex;

		margin-bottom: 10vh;

		div {
			h4 {
				font-family: 'Aeonik-Medium';
				font-style: normal;
				font-weight: 500;
				@media screen and (max-width: 1450px) {
					font-size: 19px;
				}
			}
			display: flex;
			flex-direction: column;
			ul {
				padding: 0 !important;
				li {
					list-style: none;
					display: flex;
					a {
						font-family: 'Aeonik-Regular';
						font-style: normal;
						font-weight: 400;
						color: rgba(255, 255, 255, 1);
						text-decoration: none;
						margin-bottom: 0.5vh;
					}
				}
			}
		}
	}

	.footer-end {
		background-color: black;
		color: #fff;
		padding: 27px 0;
		font-size: 0.8vw;
		text-align: center;
		margin-bottom: 5vh;
		&-div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			max-width: 1720px;
			padding: 0 30px;
			margin: 0 auto;
			p {
				padding: 27px 0;
				font-style: normal;
				color: rgba(102, 102, 102, 1);
				margin: 0;
			}
		}
	}
}
