.portfolio-section {
	min-height: 100vh;

	// padding: 0 !important;

	@media screen and (min-width: 1768px) {
		padding: 0 !important;
	}

	.nav {
		margin-top: 15vh;
		display: flex;
		justify-content: space-between;
		align-items: end;

		a {
			font-size: 1.25vw;
			border: transparent;
			color: rgba(170, 170, 170, 1);
			font-style: normal;
			font-family: 'Aeonik-Regular';
			font-weight: 400;
			text-transform: uppercase;
			transition: 0.5s ease all;
			&[aria-selected='true'] {
				color: rgba(34, 34, 34, 1) !important;
				font-weight: bold;
				font-size: 1.25vw;
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: 1px solid #000 !important;
				z-index: 3;
			}
			&[data-rb-event-key='home'] {
				padding-left: 0;
				color: rgba(0, 0, 0, 1) !important;
				font-weight: 700;
				font-size: 4.5vw;
				font-style: normal;
				font-family: 'Aeonik-Bold';
			}
			&:hover {
				color: #000000;
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: 1px solid #000 !important;
			}
		}
	}
}
@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}
