header.header-remove {
	height: 10vh;
	// width: 0;
	z-index: 100;
	background-color: rgba(34, 34, 34, 1);

	// display: none;
	& > * {
		color: #fff !important;
	}
}
header.header-remove-home {
	height: 10vh;
	// width: 0;
	z-index: 100;

	// display: none;
	& > * {
		color: #fff !important;
	}
}
.header-delete {
	display: none !important;
}
.header {
	&__content {
		justify-content: space-between;
		align-items: center;
		max-width: 1720px;
		margin: 0 auto;

		padding: 0 20px;
	}
	// maxwidth: 100vw;
	// padding 0 0.5rem;

	.toggle__icon {
		display: flex;
	}
	.mobile-header {
		position: absolute;
		background-color: #000;
		top: 0;
		right: -20vw;
		z-index: 9999;
		transform: translateX(100%);
		display: none;
		flex-direction: column;
		transition: all 0.3s ease-in-out;

		&.active {
			transform: translateX(0);
			left: 0;
		}
		&__head {
			margin: 5vh;
			display: flex;
			justify-content: space-between;
		}
		&__main {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-top: 5vh;
			.header__menu-item-mobile {
				margin-top: 5vh;
				color: #fff;
			}
			.header__menu-item-mobile > * {
				font-size: 7vw;
				text-transform: uppercase;
				color: #fff;
				text-decoration: none;
			}
		}
	}

	font-family: 'Aeonik-Light';
	// background-color: #fff;

	height: 15vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// transition: all 0.1s linear;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 10;

	.header__logo {
		display: flex;
		justify-content: flex-end;
	}
	.header__menu {
		justify-content: center;
		.header__menu-item {
			text-transform: uppercase;
			margin: 0 1rem;
			font-weight: 400;
			font-size: 0.8vw;
			color: rgba(34, 34, 34, 1);
			// color: #fff;
			font-family: 'Aeonik-Regular';
			font-style: normal;
			letter-spacing: 1.6px;

			&::after {
				content: '';
				display: block;
				width: 0;
				height: 2px;
				background-color: rgba(59, 59, 59, 1);
				transition: width 0.3s ease-in-out;
			}

			&:hover {
				&::after {
					width: 100%;
				}
			}
			& > * {
				color: rgba(59, 59, 59, 1);
				text-decoration: none;
			}
		}
	}
	.toggle__icon {
		margin-right: 5vw;

		span {
			svg {
				width: 2vw !important;
				height: 2vw !important;
			}
		}
	}
}
