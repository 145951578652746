.main2,
.main1 {
	position: relative;
	height: 20vh;
}
.main1 {
	transform: translateY(40%);
}
.con1 {
	position: absolute;
	height: 10vh;
	z-index: 2;
	width: 100%;
	background: transparent;
	top: 25%;
}
.con2 {
	z-index: 4;
	height: 15vh;
	width: 100%;
	position: absolute;
	background: transparent;
	top: 25%;
}
.l1 {
	z-index: -1;
	animation: anim 0.5s ease-out;
	position: absolute;
	transform: translateY(-50%);
}
.l2 {
	position: absolute;
	z-index: 3;
	animation: anim2 0.4s ease-out;
	transform: translateY(-50%);
}

@keyframes anim {
	0% {
		transform: translateY(10%);
		opacity: 0;
	}
	100% {
		transform: translateY(-50%);
		opacity: 1;
	}
}
@keyframes anim2 {
	0% {
		transform: translateY(10%);
		opacity: 0;
	}
	100% {
		transform: translateY(-50%);
		opacity: 1;
	}
}
