.portfolio-detail {
	padding: 0;
	margin-top: 200px !important;

	&-title-div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&-title {
		// width: 100%;
		margin: 5vh 0vw;
		min-width: 40vw;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		text-transform: uppercase;
		font-weight: 700;
		font-style: normal;
		font-family: 'Aeonik-Bold';
		line-height: 8vw;
		font-size: 8.3vw;
		color: rgba(0, 0, 0, 1);
		cursor: default;
	}
	&-title > * {
		text-transform: uppercase;
		font-weight: 700;
		font-style: normal;
		font-family: 'Aeonik-Bold';
		line-height: 5.83vw;
		font-size: 8.3vw;
		color: rgba(0, 0, 0, 1);
	}

	&-description {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		h4 {
			font-weight: 700;
			line-height: 1.5vw;
			font-size: 1.25vw;
			font-style: normal;
			font-family: 'Aeonik-Regular';
			text-transform: uppercase;
			color: rgba(0, 0, 0, 1);
			margin: 0;
			cursor: default;
		}
		div {
			width: 100%;
			display: flex;

			justify-content: center;
			align-items: center;
			h4 {
				margin: 0 1vh;
				font-family: 'Aeonik-Bold';
			}
		}
		&-image {
			width: 100%;
			overflow: hidden;
			margin-top: 3vh;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: all 0.6s ease;

				&:hover {
					transform: scale(1.02);
				}
			}
		}
	}

	.portfolio-detail-paragraph {
		display: flex;
		justify-content: space-between;
		&-item {
			h2 {
				font-weight: 500;
				line-height: 2.5vw;
				font-style: normal;
				font-size: 2vw;
				font-family: 'Aeonik-Medium';
				color: rgba(34, 34, 34, 1);
				margin: 0;
			}
			#main {
				text-transform: uppercase;
				color: rgba(34, 34, 34, 1);
				font-weight: 200;
				font-size: 1.25vw;
				font-style: normal;
				font-family: 'Aeonik-Light';
			}
			h3 {
				font-weight: 500;
				line-height: 1.5vw;
				font-style: normal;
				font-size: 1.25vw;
				font-family: 'Aeonik-Medium';
				color: rgba(34, 34, 34, 1);
				margin: 0;
				text-align: start;
			}
			#info {
				color: rgba(34, 34, 34, 1);
				font-weight: 200;
				font-size: 1.25vw;
				font-style: normal;
				font-family: 'Aeonik-Light';
				width: 70%;
			}
		}
	}
	.proekt-images {
		width: 100%;
		.image {
			width: 100%;
			overflow: hidden;
			margin-top: 3vh;
			img {
				width: 100%;
				transition: ease all 0.6s;
				// height: 100%;
				object-fit: cover;
				cursor: default;
				&:hover {
					transform: scale(1.02);
				}
			}
		}
		.image:last-child {
			margin-bottom: 3vh;
		}
	}
}
