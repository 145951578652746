.project-element {
	display: flex;
	width: 100%;

	// .images-div:nth-child(2) {
	// 	margin: 3vh;
	// 	height: 80%;
	// }

	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	// grid-template-areas:
	// 	'image-1 image-2 image-3'
	// 	'image-1 image-2 image-3'
	// 	'image-1 image-2 image-3';
	grid-gap: 1vw;
	// flex-direction: column;
	width: 100%;
	height: 100%;

	.image-div {
		position: relative;
		overflow: hidden;
		a {
			cursor: none !important;
		}
		.overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			right: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			transition: 0.5s ease;
			background-color: rgba(0, 0, 0, 1);
		}
		.text {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			text-align: center;
			h1 {
				text-transform: uppercase;
				font-weight: 700;
				text-align: center;
				color: rgba(255, 255, 255, 1);
				font-size: 32px;
				font-family: 'Aeonik-Bold';
			}
			span {
				font-weight: 400;
				font-style: normal;
				font-family: 'Aeonik-Regular';
				color: rgba(176, 176, 176, 1);
				font-size: 24px;
			}
		}
		&:hover .overlay {
			opacity: 0.8 !important;
		}
		&:hover img {
			transform: scale(1.01);
			// opacity: 0.4;
		}
	}
	img:not(:first-child) {
		margin-top: 3vh;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 0;
		margin: 0 !important;
		transition: all 0.5s ease;
	}
}
